@font-face {
  font-family: "Christmas";
  src: url("./MountainsofChristmas-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Christmas Bold";
  src: url("./MountainsofChristmas-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Emilys Candy";
  src: url("./EmilysCandy-Regular.ttf") format("truetype");
}
